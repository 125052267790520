import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { DEFAULT_PRODUCT_FAMILY_COLOR } from 'utils/constants';
import { removeHTMLTags } from 'utils/helpers';

import CustomCard from 'components/CustomCard';
import NFButton from 'components/common/NFButton';
import ControlledCarousel from 'components/ControlledCarousel';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import responsiveObj from './constants';
import { IPropsFeaturedArticles } from './model';
import './FeaturedArticles.scss';

const FeaturedArticles: FC<IPropsFeaturedArticles> = ({
  bgColor,
  title,
  subtitle,
  articles,
  btn,
  mode,
  articlesByTag,
  carouselControls,
  featuredArticlesAnchor,
}) => {
  const bgColorApplied = bgColor?.[0]?.properties.colorPicker?.label
    ? ` ${bgColor[0].properties.colorPicker.label}-default-bg`
    : '';
  const classes = classNames('nf-related-articles', {
    [bgColorApplied]: bgColorApplied,
  });

  const isModeByTag = mode?.[0] === 'by Tag';

  return articles?.length || articlesByTag?.length ? (
    <div
      className="campaign-anchor-section"
      {...(featuredArticlesAnchor ? { id: featuredArticlesAnchor } : {})}
    >
      <div data-test="FeaturedArticles" className={classes}>
        <Container fluid>
          {title ? (
            <h2
              className="nf-related-articles__title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          ) : null}
          {subtitle ? (
            <DangerouslySetInnerHtml className="nf-related-articles__subtitle" html={subtitle} />
          ) : null}

          <ControlledCarousel
            classes="nf-articles-carousel"
            responsiveObj={responsiveObj}
            bgGradientColor={bgColor?.[0]?.properties.colorPicker?.label}
            carouselControls={carouselControls}
          >
            {!isModeByTag
              ? articles?.length
                ? articles.map((article) => (
                    <CustomCard
                      cardLink={{ link: article.url }}
                      key={article.title}
                      title={article.title}
                      label={article.preferred?.[0]?.title}
                      labelBgColor={
                        article?.productFamily?.[0]?.articleListingBadgeBgColor?.[0]?.properties
                          ?.colorPicker?.label || DEFAULT_PRODUCT_FAMILY_COLOR
                      }
                      text={removeHTMLTags(article.description)}
                      img={
                        article.featuredImage.image?.gatsbyImage?.childImageSharp
                          ? article.featuredImage.image
                          : article.featuredImage.image.fallbackUrl
                      }
                      imageAlt={article.featuredImage.imageAlt}
                      imgType="img"
                      classes="nf-article-card"
                    />
                  ))
                : []
              : articlesByTag?.length
              ? articlesByTag.map((article) => (
                  <CustomCard
                    cardLink={{ link: article.link }}
                    key={article.title}
                    title={article.title}
                    label={article.preferred?.[0]?.title}
                    labelBgColor={
                      article?.productFamily?.[0]?.articleListingBadgeBgColor?.[0]?.properties
                        ?.colorPicker?.label || DEFAULT_PRODUCT_FAMILY_COLOR
                    }
                    text={removeHTMLTags(article.description)}
                    img={
                      article.featuredImage[0].properties.image?.gatsbyImage?.childImageSharp
                        ? article.featuredImage[0].properties.image
                        : article.featuredImage[0].properties.image.fallbackUrl
                    }
                    imageAlt={article.featuredImage[0].properties.imageAlt}
                    imgType="img"
                    classes="nf-article-card"
                  />
                ))
              : []}
          </ControlledCarousel>

          {btn ? <NFButton {...btn} /> : null}
        </Container>
      </div>
    </div>
  ) : null;
};

export const query = graphql`
  fragment FragmentFeaturedArticleData on IUmbracoArticleBase {
    title
    link
    isVideoPage
    lang
    description
    preferred {
      title
      id
    }
    tags {
      id
      title
      taxonomy
    }
    productFamily {
      ...FragmentProductFamily
    }
    featuredImage {
      properties {
        image {
          fallbackUrl
          gatsbyImage {
            childImageSharp {
              fixed(width: 335, height: 250, cropFocus: ENTROPY) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        imageAlt
      }
    }
  }

  fragment FragmentFeaturedArticles on IFeaturedArticles {
    featuredArticlesBgColor {
      properties {
        colorPicker {
          label
        }
      }
    }
    featuredArticlesCTAButtonData {
      properties {
        ...FragmentNFButton
      }
    }
    featuredArticlesSectionSubtitle
    featuredArticlesSectionTitle
    featuredArticlesData {
      preferred {
        title
        id
      }
      description
      title
      productFamily {
        ...FragmentProductFamily
      }
      featuredImage {
        image {
          fallbackUrl
          gatsbyImage {
            childImageSharp {
              fixed(width: 335, height: 250, cropFocus: ENTROPY) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        imageAlt
      }
      url
    }
    featuredArticlesSelectedArticleMode
    featuredArticlesSelectedArticlesTag {
      title
    }
    featuredArticlesAnchor
  }
`;

export default FeaturedArticles;
