import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import CardLabel from 'components/common/CardLabel';

import { CustomCardProps } from './model';
import './CustomCard.scss';

const CustomCard: FC<CustomCardProps> = ({
  label,
  labelBgColor = 'navy',
  title,
  text,
  img,
  imageAlt,
  imgType = 'bg',
  classes,
  bg,
  isLazyLoading,
  cardLink: { link },
}) => {
  const imageClasses = classNames('', {
    'nf-card__img type--bg': imgType === 'bg',
    'nf-card__img type--img': imgType !== 'bg',
  });

  const wrapperClasses = classNames('', {
    'nf-card__img-bg': imgType === 'bg',
    'nf-card__img-usual': imgType !== 'bg',
  });

  return (
    <div
      className={`${classes} card`}
      style={
        bg &&
        ({
          backgroundColor: `var(--${bg})`,
        } as React.CSSProperties)
      }
    >
      <Link className="nf-card-holder-link" to={link}>
        <>
          {label ? <CardLabel label={label} labelBgColor={labelBgColor} /> : null}
          <UniversalImage
            imageAlt={imageAlt || ''}
            img={img}
            wrapperClassName={wrapperClasses}
            imageClassName={imageClasses}
            isLazyLoading={isLazyLoading}
          />
          <div className="card-body">
            <div className="card-title" title={title}>
              {title}
            </div>
            <DangerouslySetInnerHtml className="card-text" html={text} />
          </div>
        </>
      </Link>
    </div>
  );
};

export default CustomCard;
