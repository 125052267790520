const responsiveObj = {
  desktop: {
    breakpoint: { max: 6000, min: 992 },
    items: 3,
    partialVisibilityGutter: 36,
  },
  tablet: {
    breakpoint: { max: 991, min: 640 },
    items: 2,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 639, min: 0 },
    items: 1,
    partialVisibilityGutter: 9,
  },
};

export { responsiveObj as default };
