import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import classNames from 'classnames';

import { DEFAULT_PRODUCT_FAMILY_COLOR } from 'utils/constants';
import { removeHTMLTags } from 'utils/helpers';

import responsiveObj from 'components/FeaturedArticles/constants';
import CustomCard from 'components/CustomCard';
import NFButton from 'components/common/NFButton';
import ControlledCarousel from 'components/ControlledCarousel';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IExtendedFeaturedArticlesComponentProps } from './model';
import 'components/FeaturedArticles/FeaturedArticles.scss';

const ExtendedFeaturedArticles: FC<IExtendedFeaturedArticlesComponentProps> = ({
  mode,
  title,
  subtitle,
  button,
  bgColor,
  carouselControls,
  articlesByLink,
  articlesByTag,
}) => {
  const bgColorApplied = bgColor?.[0]?.properties?.colorPicker?.label
    ? ` ${bgColor[0].properties.colorPicker.label}-default-bg`
    : '';
  const classes = classNames('nf-related-articles', {
    [bgColorApplied]: bgColorApplied,
  });

  const isModeByTag = mode?.[0] === 'by Tag';

  return articlesByLink?.length || articlesByTag?.length ? (
    <div data-test="FeaturedArticles" className={classes}>
      <Container fluid>
        {title ? (
          <h3 className="nf-related-articles__title" dangerouslySetInnerHTML={{ __html: title }} />
        ) : null}
        {subtitle ? (
          <DangerouslySetInnerHtml className="nf-related-articles__subtitle" html={subtitle} />
        ) : null}

        <ControlledCarousel
          classes="nf-articles-carousel"
          responsiveObj={responsiveObj}
          bgGradientColor={bgColor?.[0]?.properties.colorPicker?.label}
          carouselControls={carouselControls}
        >
          {!isModeByTag
            ? articlesByLink?.length
              ? articlesByLink
                  .filter((article) => article)
                  .map((article) => (
                    <CustomCard
                      cardLink={{ link: article.link }}
                      key={article.title}
                      title={article.title}
                      label={article.labelName}
                      labelBgColor={article.labelColor || DEFAULT_PRODUCT_FAMILY_COLOR}
                      text={removeHTMLTags(article.description)}
                      img={
                        article.previewImage.image?.mobileImage?.childImageSharp ||
                        article.previewImage.image?.gatsbyImage?.childImageSharp
                          ? article.previewImage.image
                          : article.previewImage.image.fallbackUrl
                      }
                      imageAlt={article.previewImage?.imageAlt}
                      imgType="img"
                      classes="nf-article-card"
                    />
                  ))
              : []
            : articlesByTag?.length
            ? articlesByTag
                .filter((article) => article)
                .map((article) => (
                  <CustomCard
                    cardLink={{ link: article.link }}
                    key={article.title}
                    title={article.title}
                    label={article.labelName}
                    labelBgColor={article.labelColor || DEFAULT_PRODUCT_FAMILY_COLOR}
                    text={removeHTMLTags(article.description)}
                    img={
                      article.previewImage.image?.mobileImage?.childImageSharp ||
                      article.previewImage.image?.gatsbyImage?.childImageSharp
                        ? article.previewImage.image
                        : article.previewImage.image.fallbackUrl
                    }
                    imageAlt={article.previewImage?.imageAlt}
                    imgType="img"
                    classes="nf-article-card"
                  />
                ))
            : []}
        </ControlledCarousel>

        {button ? <NFButton {...button} /> : null}
      </Container>
    </div>
  ) : null;
};

export default ExtendedFeaturedArticles;
