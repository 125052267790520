import { parseNFButton } from 'components/common/NFButton/parsers';
import { IExtendedFeaturedArticleCard, IExtendedFeaturedArticles } from './model';

export const parseExtendedFeaturedArticlesData = (articles): IExtendedFeaturedArticleCard[] => {
  return articles?.length
    ? articles.map((article) => {
        if (article?.__typename === 'UmbracoArticles') {
          return {
            link: article?.link,
            title: article?.title,
            description: article?.description,
            previewImage: article?.featuredImage?.[0]?.properties,
            labelName: article?.preferred?.[0]?.title,
            labelColor:
              article?.productFamily?.[0]?.articleListingBadgeBgColor?.[0]?.properties?.colorPicker
                ?.label,
          };
        }
        if (article?.__typename === 'UmbracoSymptoms') {
          return {
            link: article?.link,
            title: article?.title,
            description: article?.dataForExtendedRelatedArticles?.[0]?.properties?.teaser,
            previewImage:
              article?.dataForExtendedRelatedArticles?.[0]?.properties?.previewImage?.[0]
                ?.properties,
            labelName: article?.dataForExtendedRelatedArticles?.[0]?.properties?.labelName,
            labelColor:
              article?.dataForExtendedRelatedArticles?.[0]?.properties?.labelColor?.[0].properties
                ?.colorPicker?.label,
          };
        }

        return null;
      })
    : [];
};

export const parseExtendedFeaturedArticles = ({
  extendedFeaturedArticles,
  articlesByTag,
  articlesByLink,
  carouselControls,
}): IExtendedFeaturedArticles => ({
  mode: extendedFeaturedArticles.extendedFeaturedArticlesSelectedArticleMode,
  title: extendedFeaturedArticles.extendedFeaturedArticlesSectionTitle,
  subtitle: extendedFeaturedArticles.extendedFeaturedArticlesSectionSubtitle,
  bgColor: extendedFeaturedArticles.extendedFeaturedArticlesBgColor,
  button: extendedFeaturedArticles.extendedFeaturedArticlesCTAButtonData?.length
    ? {
        ...parseNFButton(
          extendedFeaturedArticles.extendedFeaturedArticlesCTAButtonData[0].properties
        ),
      }
    : undefined,
  carouselControls,
  articlesByLink: parseExtendedFeaturedArticlesData(articlesByLink),
  articlesByTag: parseExtendedFeaturedArticlesData(articlesByTag),
});
